<template>
  <v-card :key="modalKey">
      <v-card-title>
        <span class="headline">Création d'un module</span>
      </v-card-title>
      <v-card-text>
        <v-container> 
          <v-row> 
            <v-col>
              <v-text-field 
                v-model="module" 
                label="Module*" 
                :rules="[moduleRule]"
              >
            </v-text-field>
            </v-col> 
          </v-row>
          <!-- logo -->
          <!-- <v-row>  
            <v-col>
              <v-file-input
                type="file"
                @change="handleLogoChange"
                :value="logo" 
                accept="image/*"
                label="Logo*"
                :rules="[logoRule]"
              ></v-file-input>
            </v-col>
          </v-row> -->
          <!-- lien vers le référentiel Git -->
          <v-row>
            <v-col>
              <v-text-field 
                v-model="lienGit"
                label="Lien vers le référentiel Git" 
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- description -->
          <v-row>
            <v-col>
              <v-textarea
                v-model="description"
                label="Description*"
                :rules="[descriptionRule]"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- voir le logo selectionné -->
          <!-- <v-row v-if="newLogoPreview">
            <v-img 
              :src="newLogoPreview" 
              height="20vh"
              width="10vw"
              contain
            >
            </v-img>
          </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal">Annuler</v-btn>
        <v-btn color="success" text @click="saveModule" :disabled="!isValidate">Créer</v-btn>
      </v-card-actions>
  </v-card> 
</template>
<script>

import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import Images from '@/Utils/Images';

export default {
  name: "createModule",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect")
  },
  data() {
    return {
      version: '',
      users: [],
      usersSelected: [],
      module: '',
      sousModule: '',
      technologie: '',
      description: '',
      lienGit: '',
      file: null, 
      logo: null,
      fileName: null,
      newLogoPreview: null,
      data: [],
      dataSousModule: [],
      modalKey: 0,
      // userSelectRule: (v) => v && v.length > 0 || 'Ce champ est requis',
      // sousModuleRule: (v) => !!v || 'Ce champ est requis',
      // logoRule: (v) => !!v || 'Ce champ est requis',
      // versionRule: (v) => !!v || 'Ce champ est requis',
      moduleRule: (v) => !!v || 'Ce champ est requis',
      // technologieRule: (v) => !!v || 'Ce champ est requis',
      descriptionRule: (v) => !!v || 'Ce champ est requis',
    }
  },  
  computed: {
    isValidate() {
      return !!this.module && !!this.description;
    }
  },
  methods: {
    reset() {
      this.version = '';
      this.usersSelected = [];
      this.module = '';
      this.sousModule = '';
      this.technologie = '';
      this.description = '';
      this.logo = null;
      this.lienGit = '';
      this.fileName = null;
      this.newLogoPreview = null;
      this.modalKey += 1;
      // this.userSelectRule = (v) => v && v.length > 0 || 'Ce champ est requis';
      // this.sousModuleRule = (v) => !!v || 'Ce champ est requis';
      // this.logoRule = (v) => !!v || 'Ce champ est requis';
      // this.versionRule = (v) => !!v || 'Ce champ est requis';
      this.moduleRule = (v) => !!v || 'Ce champ est requis';
      // this.technologieRule = (v) => !!v || 'Ce champ est requis';
      this.descriptionRule = (v) => !!v || 'Ce champ est requis';
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal-create');
    },
    openModalSousModule() {
      this.dialogSousModule = true
    },
    // async handleLogoChange(file) {
    //   if (file) {
    //     let b64 = await Images.toBase64(file);
    //     this.newLogoPreview = b64;
    //     this.logo = file
    //   } else {
    //     this.newLogoPreview = null;
    //     this.logo = null;
    //   }
    // },
    async saveModule() {
      const formData = new FormData(); 
      formData.append('module', this.module); 
      // formData.append('logo', this.logo);
      formData.append('lienGit', this.lienGit);
      formData.append('description', this.description);
      await ModuleService.createModule(formData);
      this.$emit('refresh');
      this.closeModal();
    }, 
  },
  async mounted() { 
     
  },
}
</script>

